"use client";

import { useEffect, useRef, useState } from "react";
import ReactLazy from "react-lazyload";
import { gtmEvent } from "utils/gtm";
import { cn } from "../../utils";
import { useMounted } from "../../hooks/use-mounted";

export interface AdType {
  networkCode: string;
  adUnit: string;
  targetingArguments?: Record<string, string[] | string>;
}

interface AdProps extends AdType {
  name: string;
  id?: string;
  height?: number;
  width?: number;
  className?: string;
  mobile?: boolean;
  hideOnTablet?: boolean;
  desktop?: boolean;
  sticky?: boolean;
  biggerStickyTop?: boolean;
  largeStickyTop?: boolean;
  useRelativeForSticky?: boolean;
  isTwitchLive?: boolean;
  sizes: googletag.GeneralSize;
}

const StickyWrapper = ({
  sticky,
  biggerStickyTop,
  largeStickyTop,
  isTwitchLive,
  children,
}: {
  sticky: boolean;
  biggerStickyTop?: boolean;
  largeStickyTop?: boolean;
  isTwitchLive?: boolean;
  children: React.ReactNode;
}) =>
  sticky ? (
    <div
      className={cn("relative", {
        "sticky top-4": sticky,
        "top-24": biggerStickyTop,
        "top-36": biggerStickyTop && isTwitchLive,
        "top-52": largeStickyTop,
      })}
    >
      {children}
    </div>
  ) : (
    children
  );

const Ad = ({
  height,
  width,
  className,
  mobile,
  desktop,
  sizes,
  sticky = false,
  biggerStickyTop = false,
  hideOnTablet,
  largeStickyTop = false,
  useRelativeForSticky = false,
  isTwitchLive = false,
  ...rest
}: AdProps) => {
  const mounted = useMounted();
  const heightStyle = typeof height !== "undefined" ? `${height}px` : "100%";
  const widthStyle = typeof width !== "undefined" ? `${width}px` : "100%";

  const stickyContainerStyle =
    sticky && width
      ? {
          top: "0",
          bottom: "0",
          width: widthStyle,
          left: `${0 - width - 16}px`,
        }
      : {};

  if (!mounted)
    return (
      <div
        style={{ width: widthStyle, height: heightStyle }}
        className={cn(
          {
            "hidden lg:flex": desktop && !hideOnTablet,
            "flex lg:hidden": mobile,
            "hidden xl:flex": desktop && hideOnTablet,
          },
          { absolute: sticky },
          className
        )}
      />
    );

  return (
    <ReactLazy
      placeholder={<div style={{ width: widthStyle, height: heightStyle }} />}
      className={cn(
        "relative mx-auto flex items-center justify-center",
        {
          "hidden lg:flex": desktop && !hideOnTablet,
          "flex lg:hidden": mobile,
          "hidden xl:flex": desktop && hideOnTablet,
        },
        { "absolute h-full items-start": sticky },
        { " relative": useRelativeForSticky },
        { "w-full": sizes === "fluid" },
        className
      )}
      style={stickyContainerStyle}
      once
    >
      <StickyWrapper
        sticky={sticky}
        biggerStickyTop={biggerStickyTop}
        isTwitchLive={isTwitchLive}
        largeStickyTop={largeStickyTop}
      >
        <AdSlot height={height} width={width} sizes={sizes} {...rest} />
      </StickyWrapper>
    </ReactLazy>
  );
};

const AdSlot = ({
  adUnit,
  networkCode,
  sizes,
  width,
  height,
  name,
  id,
  targetingArguments,
}: AdProps) => {
  const [loaded, setLoaded] = useState(false);
  const refreshInterval = useRef<NodeJS.Timeout>();
  const hasRefreshed = useRef(false);
  const divId = id ? `${name}_${id}` : name;
  const heightStyle = typeof height !== "undefined" ? `${height}px` : "100%";
  const widthStyle = typeof width !== "undefined" ? `${width}px` : "100%";

  useEffect(() => {
    if (typeof window === "undefined") return;

    const setTargeting = (slot: googletag.Slot) => {
      if (!targetingArguments) return;

      Object.keys(targetingArguments).forEach((key) => {
        const value = targetingArguments[key];
        if (value) slot.setTargeting(key, value);
      });
    };

    let adSlot: googletag.Slot | undefined;
    if (!googletag.apiReady) return;

    window.googletag.cmd.push(() => {
      adSlot = googletag
        .defineSlot(`/${networkCode}/${adUnit}/${name}`, sizes, divId)
        ?.addService(googletag.pubads());

      if (adSlot) {
        if (targetingArguments) setTargeting(adSlot);
        setEvents(adSlot);
      }

      googletag.pubads().setCentering(true);
      googletag.enableServices();
      googletag.display(divId);
    });

    return () => {
      if (adSlot) googletag.destroySlots([adSlot]);
    };
  }, []);

  const setEvents = (targetSlot: googletag.Slot) => {
    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
      if (event.slot === targetSlot) {
        setLoaded(true);
      }
    });
    googletag.pubads().addEventListener("impressionViewable", (event) => {
      const slot = event.slot;

      if (event.slot === targetSlot) {
        gtmEvent("visible_impression", { adunit: slot.getAdUnitPath() });
      }
    });
    googletag.pubads().addEventListener("slotVisibilityChanged", (event) => {
      const slot = event.slot;

      if (slot === targetSlot) {
        if (
          event.inViewPercentage > 0 &&
          !refreshInterval.current &&
          !hasRefreshed.current
        ) {
          refreshInterval.current = setTimeout(() => {
            googletag.pubads().refresh([slot]);
            hasRefreshed.current = true;
          }, 30000);
        } else if (event.inViewPercentage === 0 && refreshInterval.current) {
          clearTimeout(refreshInterval.current);
        }
      }
    });
  };

  return (
    <>
      {!loaded && <div style={{ width: widthStyle, height: heightStyle }} />}
      <div id={divId} className={cn({ "w-full": sizes === "fluid" })} />
    </>
  );
};

export { Ad };
